import React from "react"
import { Tween } from "react-gsap"

function ParallaxImage({ pX, pY, movement, heroRef, name, classes }) {
  const parallaxIt = () => {
    const th = heroRef.current

    const relX = pX - th.offsetLeft
    const relY = pY - th.offsetTop

    const width = th.getBoundingClientRect().width
    const height = th.getBoundingClientRect().height

    let animX = ((relX - width / 2) / width) * movement
    let animY = ((relY - height / 2) / height) * movement

    return { x: animX * 5, y: animY * 5 }
  }

  if (!heroRef.current) return (<div className={classes}>
    <img alt="no surrender" src={name} />
  </div>)

  return (
    <Tween to={parallaxIt()} duration={1}>
      <div className={classes}>
        <img alt="no surrender" src={name} />
      </div>
    </Tween>
  )
}

export default ParallaxImage
