import { useState } from "react"

const useMove = () => {
  const [state, setState] = useState({ x: 0, y: 0 })

  const handleMouseMove = e => {
    e.persist()
    setState(state => ({
      ...state,
      x: e.clientX,
      y: e.clientY,
      pageX: e.pageX,
      pageY: e.pageY,
    }))
  }
  return {
    x: state.x,
    y: state.y,
    pageX: state.pageX,
    pageY: state.pageY,
    handleMouseMove,
  }
}

export default useMove
